exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-entering-goa-with-a-pet-js": () => import("./../../../src/pages/entering-goa-with-a-pet.js" /* webpackChunkName: "component---src-pages-entering-goa-with-a-pet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-methods-js": () => import("./../../../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-past-reports-js": () => import("./../../../src/pages/past-reports.js" /* webpackChunkName: "component---src-pages-past-reports-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rabies-controlled-area-js": () => import("./../../../src/pages/rabies-controlled-area.js" /* webpackChunkName: "component---src-pages-rabies-controlled-area-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

